import { Component } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { UUID } from 'angular2-uuid'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'kuhnekt-project'

  constructor(private cookie: CookieService) {}

  ngOnInit() {
    const uuidValue = UUID.UUID()
    if (!this.cookie.get('kwa_session_uid')) {
      this.cookie.set('kwa_session_uid', uuidValue, {
        expires: 1,
        path: '/',
        secure: true,
        sameSite: 'Strict',
      })
    }
  }
}
