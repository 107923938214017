import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { CategoryService } from 'src/app/Services/category.service'

@Component({
  selector: 'app-list-categories',
  templateUrl: './list-categories.component.html',
  styleUrls: ['./list-categories.component.scss'],
})
export class ListCategoriesComponent implements OnInit {
  Categories!: any
  title = 'Categories'
  totalLength: any
  page: number = 1

  constructor(
    private categoryService: CategoryService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.categoryService.getCategory().subscribe((items) => {
      this.Categories = items
      this.totalLength = items.length
    })
  }
}
