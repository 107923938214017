<div class="main-categories-container" *ngIf="categoryDetails">
    <div class="categories-sub-container" *ngIf="categoryDetails.length>0">
        <div class="main-text-container">
            <div class="welcome-text-container">
                <h3 class="welcome-text">
                    {{categoryDetails[0].title | titlecase}}
                </h3>
            </div>
        </div>
        <div class="bread-crumb-container">
            <div class="bread-crumb-name" routerLink=''>Home &nbsp;</div>
            <div class="bread-crumb-name" *ngFor="let item of breadcrumbData" routerLink='/categories/{{item.id}}'>
                /&nbsp;{{item.title}} &nbsp;
            </div>
        </div>
        <div class="back-button-arrow">
            <mdb-icon fas icon="arrow-left"
              class="back-button"
              (click)="goBack()">
            </mdb-icon>
        </div>

        <div class="category-listing-container" 
            *ngIf="categoryDetails[0].sub_categories.length>0">
            <div >
                <h1 class="title-name">Categories</h1>
            </div>
            <div class='Categories'>
                <div *ngFor="let category of categoryDetails[0].sub_categories |
                            paginate: { itemsPerPage: 8,
                            currentPage: page,
                            totalItems:totalLength
                            }"
                            class='category-item' 
                            (click)="nextApi(category.id)">
                    <div class='category-name'>{{category.title}}</div>
                </div>
            </div>
            <div *ngIf="(categoryDetails[0].sub_categories.length)>8" class="pagination-container">
                <pagination-controls class="my-pagination" (pageChange)="page = $event">
                </pagination-controls>
            </div>
        </div>

        <div class="category-listing-container" *ngIf="categoryDetails[0].assets.length>0">
            <div >
                <h1 class="title-name">Assets</h1>
            </div>
            <div class='Categories'>
                <div *ngFor="let category of categoryDetails[0].assets |
                      paginate: { itemsPerPage: 8,
                      currentPage: page,
                      totalItems:totalLength
                      }" 
                      class='category-item'
                      routerLink='/assets/{{category.id}}'>
                    <div class='category-name'>{{category.title}}</div>
                </div>
            </div>
            <div *ngIf="(categoryDetails[0].assets.length)>8" class="pagination-container">
                <pagination-controls class="my-pagination" (pageChange)="page = $event">
                </pagination-controls>
            </div>
        </div>

        <div class="no-item-found-container" *ngIf="categoryDetails[0].sub_categories.length+categoryDetails[0].assets.length==0">
            <p class="no-item-found">No item found</p>
        </div>

    </div>
</div>
