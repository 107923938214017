import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { MDBBootstrapModule } from 'angular-bootstrap-md'
import { NgxPaginationModule } from 'ngx-pagination'
import { Routes, RouterModule } from '@angular/router'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ListCategoriesComponent } from './Components/list-categories/list-categories.component'
import { ListSubCategoriesComponent } from './Components/list-sub-categories/list-sub-categories.component'
import { HeaderComponent } from './Components/header/header.component'
import { PageNotFoundComponent } from './Components/page-not-found/page-not-found.component'
import { AssetsCategoryDetailsComponent } from './Components/assets-category-details/assets-category-details.component'
import { SpinnerComponent } from './Components/spinner/spinner.component'
import { HttploderInterceptor } from './Interceptor/httploder.interceptor'
import { CookieService } from 'ngx-cookie-service'
import { FormsModule } from '@angular/forms'

const appRoutes: Routes = [
  { path: '', component: ListCategoriesComponent, pathMatch: 'full' },
  { path: 'categories/:id', component: ListSubCategoriesComponent },
  { path: 'assets/:id', component: AssetsCategoryDetailsComponent },
  { path: '**', component: PageNotFoundComponent, pathMatch: 'full' },
]

@NgModule({
  declarations: [
    AppComponent,
    ListCategoriesComponent,
    ListSubCategoriesComponent,
    HeaderComponent,
    AssetsCategoryDetailsComponent,
    SpinnerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPaginationModule,
    HttpClientModule,
    FormsModule,
    MDBBootstrapModule.forRoot(),
    RouterModule.forRoot(appRoutes),
  ],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: HttploderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
