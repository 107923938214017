import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'

import { CategoryService } from 'src/app/Services/category.service'

@Component({
  selector: 'app-assets-category-details',
  templateUrl: './assets-category-details.component.html',
  styleUrls: ['./assets-category-details.component.scss'],
})
export class AssetsCategoryDetailsComponent implements OnInit {
  assets!: any
  public id!: string | null
  nextButton!: any
  prevButton!: any
  breadcrumbData: any

  constructor(
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params['id']
      this.categoryService.getAssetsById(this.id).subscribe((items) => {
        this.assets = items
        if (
          Object.keys(this.assets).length === 0 &&
          this.assets.constructor === Object
        ) {
          this.router.navigate(['page not found'])
        } else {
          this.breadcrumbData = null
          this.filterAncestors()
        }
      })
    })
  }

  public filterAncestors() {
    if (this.assets && this.assets.ancestors.length > 0) {
      let data = this.assets.ancestors
      this.breadcrumbData = data.slice(0, 3).reverse()
    }
  }

  public nextApi(_id: string) {
    this.categoryService.getAssetsById(_id).subscribe((items) => {
      this.assets = items
      this.breadcrumbData = null
      this.filterAncestors()
    })
    this.router.navigate([`assets/${_id}`])
  }

  goBack(): void {
    this.router.navigate([`categories/${this.assets.ancestors[0].id}`])
  }
}
