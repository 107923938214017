<div class="header-container">
    <div class="company-icon-container">
        <a routerLink=''>
           <img src="assets/images/image.png" class="company-icon">
        </a> 
    </div>
    <div class="md-form search-form-container">
        <input class="search-form" (click)="toggleSearch()" [(ngModel)]="user_input"  mdbInput type="text"  placeholder="Search Anything..." >
        <mdb-icon fas icon="search" class="prefix search-image"></mdb-icon>
    </div>
    <div class="mobile-size-search-icon">
        <mdb-icon fas icon="search" (click)="toggleSearch()" class="prefix mobile-search-image"></mdb-icon>
    </div>
 
</div>

<div [ngClass]="inputHover==true?'search-container':'search-container-hide'">
    <div class="close-icon-container">
        <mdb-icon  fas icon="times" class="close-icon"  (click)= "toggleSearch()" ></mdb-icon>
    </div>
    <div class="md-form" >
            <mdb-icon fas icon="search" class="prefix search-icon-prefix" (click)="searchApi(user_input)">
            </mdb-icon>
        
        <input class="search-form" [(ngModel)]="user_input" (keypress)="onKeypressEvent($event)" mdbInput type="text"
            placeholder="Search Anything...">
    </div>

    <div class="search-result" *ngIf="(list)">
        <div *ngIf="(list.categories.length)>0">
            <div class="title-name">Category:</div>
            <div *ngFor="let category of list.categories" >
                <p class="category-name" (click)="pageChangeToCategory(category.id)" >{{category.title}}</p>
            </div>
        </div>
        <div *ngIf="(list.assets.length)>0">
            <h3 class="title-name">Assets:</h3>
            <div *ngFor="let asset of list.assets">
                <p class="category-name" (click)="pageChangeToAssets(asset.id)">{{asset.title}}</p>
            </div>
        </div>
        <div *ngIf="(list.categories.length+list.assets.length)==0" class="search-no-item">
            <p>
                No item found
            </p>
        </div>
    </div>

</div>




