import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

import { Category } from 'src/app/Models/Category'

let hostURL = environment.apiURL

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  categoryUrl: string = `${hostURL}/web/categories`
  assetsUrl: string = `${hostURL}/web/assets`
  searchUrl: string = `${hostURL}/web/search`

  constructor(private http: HttpClient) {}

  httpHeaders = new HttpHeaders({
    'content-type': 'application/json',
  })

  getCategory(): Observable<any> {
    return this.http.get<Category[]>(`${this.categoryUrl}`, {
      headers: this.httpHeaders,
    })
  }

  getCategoryById(id: any): Observable<any> {
    const url = `${this.categoryUrl}?id=${id}`
    return this.http.get<Category[]>(`${url}`, {
      headers: this.httpHeaders,
    })
  }

  getAssetsById(id: any): Observable<any> {
    const url = `${this.assetsUrl}?id=${id}`
    return this.http.get<Category[]>(`${url}`, {
      headers: this.httpHeaders,
    })
  }

  globalSearch(user_Input: string): Observable<any> {
    const url = `${this.searchUrl}?user_input=${user_Input}`
    return this.http.get<any>(`${url}`, {
      headers: this.httpHeaders,
    })
  }
}
