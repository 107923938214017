import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { CategoryService } from 'src/app/Services/category.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user_input: string = ''
  list!: any
  inputHover: boolean = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  public searchApi(user_input: any) {
    this.categoryService.globalSearch(user_input).subscribe((item) => {
      this.list = item
    })
    this.user_input = ''
  }

  public toggleSearch() {
    this.activatedRoute.params.subscribe(() => {
      this.inputHover = !this.inputHover
      this.list = null
    })
  }
  public pageChangeToCategory(id: any) {
    this.inputHover = !this.inputHover
    this.list = null
    this.router.navigate([`categories/${id}`])
  }
  public pageChangeToAssets(id: any) {
    this.inputHover = !this.inputHover
    this.list = null
    this.router.navigate([`/assets/${id}`])
  }

  public onKeypressEvent(event: any) {
    if (event.keyCode == '13') {
      this.searchApi(this.user_input)
    }
  }
}
