import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { Location } from '@angular/common'

import { CategoryService } from 'src/app/Services/category.service'

@Component({
  selector: 'app-list-sub-categories',
  templateUrl: './list-sub-categories.component.html',
  styleUrls: ['./list-sub-categories.component.scss'],
})
export class ListSubCategoriesComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private router: Router,
    private location: Location
  ) {}
  public id!: string | null
  categoryDetails!: any
  totalLength: any
  page: number = 1
  breadcrumbData: any

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params['id']
      this.categoryService.getCategoryById(this.id).subscribe((product) => {
        this.categoryDetails = product
        if (this.categoryDetails.length == 0) {
          this.router.navigate(['page not found'])
        }
        this.breadcrumbData = null
        this.filterAncestors()
      })
    })
  }

  public filterAncestors() {
    if (
      this.categoryDetails &&
      this.categoryDetails.length > 0 &&
      this.categoryDetails[0].ancestors.length > 0
    ) {
      let data = this.categoryDetails[0].ancestors
      this.breadcrumbData = data.slice(0, 3).reverse()
    }
  }

  public nextApi(_id: string) {
    this.categoryService.getCategoryById(_id).subscribe((product) => {
      this.categoryDetails = product
      this.breadcrumbData = null
      this.filterAncestors()
    })

    this.router.navigate([`categories/${_id}`])
  }

  goBack(): void {
    this.categoryDetails[0].ancestors.length > 0
      ? this.router.navigate([
          `categories/${this.categoryDetails[0].ancestors[0].id}`,
        ])
      : this.router.navigate([''])
    this.breadcrumbData = null
    this.filterAncestors()
  }
}
