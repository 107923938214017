import { Component, OnInit, ChangeDetectorRef } from '@angular/core'

import { SpinnerService } from 'src/app/Services/spinner.service'

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  constructor(
    private spinnerService: SpinnerService,
    private cdRef: ChangeDetectorRef
  ) {}

  showSpinner = false

  ngOnInit() {
    this.init()
  }

  init() {
    this.spinnerService.getSpinnerObserver().subscribe((status) => {
      this.showSpinner = status === 'start'
      this.cdRef.detectChanges()
    })
  }
}
