<div class="main-categories-container">
    <div class="categories-sub-container" *ngIf="(Categories)">
        <div class="main-text-container">
            <div class="welcome-text-container">
                    <h1 class="welcome-text">
                        Welcome To <br /> Kuhnekt Services
                    </h1>
            </div>
        </div>

        <div class="category-listing-container">
            <div >
                <h1 class="title-name">{{title}}</h1>
            </div>
            <div class='Categories'>
                <div *ngFor="let category of Categories |
                            paginate: { itemsPerPage: 8,
                            currentPage: page,
                            totalItems:totalLength
                            }"
                         class='category-item' routerLink='/categories/{{category.id}}'>
                    <div class='category-name'>{{category.title | titlecase}}</div>
                </div>
            </div>
            <div *ngIf="(Categories.length)>8" class="pagination-container">
                <pagination-controls class="my-pagination" (pageChange)="page = $event">
                </pagination-controls>
            </div>

        </div>
    </div>
</div>

