<div class="main-assets-container" *ngIf="(assets)">
    <div class="main-text-container">
        <div class="welcome-text-container">
            <h3 class="welcome-text">
                {{assets.title | titlecase}}
            </h3>
        </div>
    </div>
    <div class="bread-crumb-container">
        <div class="bread-crumb-name" routerLink=''>Home &nbsp;</div>
        <div class="bread-crumb-name" *ngFor="let item of breadcrumbData" routerLink='/categories/{{item.id}}'>
            /&nbsp;{{item.title}} &nbsp;
        </div>
    </div>
    <div class="back-button-arrow">
        <mdb-icon fas icon="arrow-left" class="back-button" (click)="goBack()">
        </mdb-icon>
        <span class="parant-name">{{assets.ancestors[0].title}}</span>
    </div>
    <div class="assets-details-container" *ngIf="assets && (assets.tts_content!=null || assets.media_url!=null || assets.audio_url!=null)">
        <div class="assets-details-img-container" 
        *ngIf="assets.media_url!=null " > 
            <img src={{assets.media_url}}
             class="assets-details-img"/>
        </div>
        <div class="assets-details-audio-container"
         *ngIf="assets.audio_url!=null">      
            <div class="assets-audio-icon-container">
                <i class="fas fa-music assets-audio-icon"></i>
            </div>  
            <audio controls src={{assets.audio_url}}></audio>
        </div>
        <p class="assets-details-description">
            {{assets.tts_content}}
        </p>
        <div class="next-prev-button-container">
            <button type="button" 
            mdbBtn color="primary" 
            mdbWavesEffect
            [disabled]="assets.prev_asset.id!=undefined? false: true"
            (click)="nextApi(assets.prev_asset.id)"
            >previous</button>
            <button type="button" 
            mdbBtn color="primary" 
            mdbWavesEffect
            [disabled]="assets.next_asset.id!=undefined? false: true"
            (click)="nextApi(assets.next_asset.id)"
            >next</button>
        </div>
    </div>
    <div class="assets-details-container-empty" *ngIf="assets && assets.tts_content==null && assets.media_url==null && assets.audio_url==null">
        <p class="no-item-found">No asset found</p>
    </div>
</div>
